import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import Home from "../views/Home.vue";
import SignIn from "../views/SignIn.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Home,
    meta: {
      needAuth: false,
      needLogin: true,
    },
    children: [
      {
        path: "/expertlist",
        component: () => import(/* webpackChunkName: "expertList" */ "../views/expert/LineUp.vue"),
        meta: {
          needAuth: true,
          needLogin: true,
          menu: "expert",
          submenu: "lineUp",
        },
      },
      {
        path: "/expertview",
        name: "expertview",
        component: () =>
          import(/* webpackChunkName: "expertView" */ "../views/expert/ExpertView.vue"),
        meta: {
          needAuth: true,
          needLogin: true,
          menu: "expert",
          submenu: "lineUp",
        },
        props: true,
      },
      {
        path: "/products",
        component: () =>
          import(/* webpackChunkName: "productManage" */ "../views/expert/ProductManage.vue"),
        meta: {
          needAuth: true,
          needLogin: true,
          menu: "expert",
          submenu: "products",
        },
      },
      {
        path: "/expertorder",
        component: () =>
          import(/* webpackChunkName: "productManage" */ "../views/expert/ExposureOrder.vue"),
        meta: {
          needAuth: true,
          needLogin: true,
          menu: "expert",
          submenu: "inOrder",
        },
      },
      {
        path: "/terms",
        component: () =>
          import(/* webpackChunkName: "productManage" */ "../views/contents/TermsManage.vue"),
        meta: {
          needAuth: true,
          needLogin: true,
          menu: "contents",
          submenu: "terms",
        },
      },
      {
        path: "/users",
        component: () =>
          import(/* webpackChunkName: "totalUsers" */ "../views/users/TotalUsers.vue"),
        meta: {
          needAuth: true,
          needLogin: true,
          menu: "member",
          submenu: "totalList",
        },
      },
      {
        path: "/userview",
        name: "userview",
        component: () => import(/* webpackChunkName: "userView" */ "../views/users/UserView.vue"),
        meta: {
          needAuth: true,
          needLogin: true,
          menu: "member",
          submenu: "totalList",
        },
        props: true,
      },
      {
        path: "/expired",
        component: () =>
          import(/* webpackChunkName: "expiredUser" */ "../views/users/ExpiredUser.vue"),
        meta: {
          needAuth: true,
          needLogin: true,
          menu: "member",
          submenu: "expiredList",
        },
        props: true,
      },
      {
        path: "/viptrial",
        component: () => import(/* webpackChunkName: "vipTrial" */ "../views/users/VipTrial.vue"),
        meta: {
          needAuth: true,
          needLogin: true,
          menu: "member",
          submenu: "vipList",
        },
        props: true,
      },
      {
        path: "/payments",
        component: () =>
          import(/* webpackChunkName: "paymentList" */ "../views/payment/PaymentList.vue"),
        meta: {
          needAuth: true,
          needLogin: true,
          menu: "payment",
          submenu: "paidList",
        },
      },
      {
        path: "/refund",
        component: () =>
          import(/* webpackChunkName: "refundList" */ "../views/payment/RefundList.vue"),
        meta: {
          needAuth: true,
          needLogin: true,
          menu: "payment",
          submenu: "refund",
        },
      },
      {
        path: "/manage",
        component: () =>
          import(/* webpackChunkName: "adminManage" */ "../views/manage/AdminManage.vue"),
        meta: {
          needAuth: true,
          needLogin: true,
          menu: "admin",
          submenu: "manage",
        },
      },
      {
        path: "/manual",
        component: () => import(/* webpackChunkName: "eventList" */ "../views/manual/Manual.vue"),
        needAuth: false,
        needLogin: true,
      },
      {
        path: "/manageview",
        name: "manageview",
        component: () =>
          import(/* webpackChunkName: "manageView" */ "../views/manage/ManageView.vue"),
        meta: {
          needAuth: true,
          needLogin: true,
          menu: "admin",
          submenu: "manage",
        },
        props: true,
      },
      {
        path: "/smslist",
        component: () => import(/* webpackChunkName: "smsList" */ "../views/sms/SmsList.vue"),
        meta: {
          needAuth: true,
          needLogin: true,
          menu: "sms",
          submenu: "send",
        },
      },
      {
        path: "/sendsms",
        component: () => import(/* webpackChunkName: "sendSme" */ "../views/sms/SendSms.vue"),
        meta: {
          needAuth: true,
          needLogin: true,
          menu: "sms",
          submenu: "send",
        },
      },
      {
        path: "/board",
        name: "board",
        component: () =>
          import(/* webpackChunkName: "boardManage" */ "../views/contents/BoardManage.vue"),
        meta: {
          needAuth: true,
          needLogin: true,
          menu: "contents",
          submenu: "board",
        },
        props: true,
      },
      {
        path: "/noticeregist",
        name: "noticeregist",
        component: () =>
          import(/* webpackChunkName: "noticeRegist" */ "../views/contents/NoticeRegist.vue"),
        meta: {
          needAuth: true,
          needLogin: true,
          menu: "contents",
          submenu: "board",
        },
        props: true,
      },
      {
        path: "/banner",
        component: () =>
          import(/* webpackChunkName: "bannerList" */ "../views/contents/BannerList.vue"),
        meta: {
          needAuth: true,
          needLogin: true,
          menu: "contents",
          submenu: "banner",
        },
      },
      {
        path: "/bannerRegist",
        component: () =>
          import(/* webpackChunkName: "bannerRegist" */ "../views/contents/BannerRegist.vue"),
        meta: {
          needAuth: true,
          needLogin: true,
          menu: "contents",
          submenu: "banner",
        },
      },
      {
        path: "/bannerView",
        name: "bannerView",
        component: () =>
          import(/* webpackChunkName: "bannerView" */ "../views/contents/BannerView.vue"),
        meta: {
          needAuth: true,
          needLogin: true,
          menu: "contents",
          submenu: "banner",
        },
        props: true,
      },
      {
        path: "/calendarList",
        name: "CalendarList",
        component: () =>
          import(/* webpackChunkName: "calendarList" */ "../views/contents/CalendarList.vue"),
        meta: {
          needAuth: true,
          needLogin: true,
          menu: "contents",
          submenu: "calendar",
        },
      },
      {
        path: "/calendarView",
        name: "CalendarView",
        component: () =>
          import(/* webpackChunkName: "calendarView" */ "../views/contents/CalendarView.vue"),
        meta: {
          needAuth: true,
          needLogin: true,
          menu: "contents",
          submenu: "calendar",
        },
      },
      {
        path: "/lectureSeries",
        name: "LectureSeries",
        component: () =>
          import(/* webpackChunkName: "lectureSeries" */ "../views/lecture/LectureSeries.vue"),
        meta: {
          needAuth: true,
          needLogin: true,
          menu: "academy",
          submenu: "series",
        },
      },
      // {
      //   path: "/lecture-list",
      //   name: "LectureList",
      //   component: () =>
      //     import(/* webpackChunkName: "lectureList" */ "../views/lecture/LectureList.vue"),
      //   meta: {
      //     needAuth: false,
      //     needLogin: true,
      //   },
      // },
      // {
      //   path: "/lecture-regist",
      //   name: "LectureRegist",
      //   component: () =>
      //     import(/* webpackChunkName: "lectureRegist" */ "../views/lecture/LectureRegist.vue"),
      //   meta: {
      //     needAuth: false,
      //     needLogin: true,
      //   },
      //   props: true,
      // },
      {
        path: "/lecture-group-regist",
        name: "LectureGroupRegist",
        component: () =>
          import(
            /* webpackChunkName: "lectureGroupRegist" */ "../views/lecture/LectureGroupRegist.vue"
          ),
        meta: {
          needAuth: true,
          needLogin: true,
          menu: "academy",
          submenu: "series",
        },
        props: true,
      },
      {
        path: "/lecture-order",
        name: "LectureOrder",
        component: () =>
          import(/* webpackChunkName: "lectureOrder" */ "../views/lecture/LectureOrder.vue"),
        meta: {
          needAuth: true,
          needLogin: true,
          menu: "academy",
          submenu: "lecture",
        },
      },
      {
        path: "/smstemplate",
        component: () =>
          import(/* webpackChunkName: "smsTemplate" */ "../views/sms/SmsTemplate.vue"),
        meta: {
          needAuth: true,
          needLogin: true,
          menu: "sms",
          submenu: "template",
        },
      },
      {
        path: "/templatereg",
        component: () =>
          import(/* webpackChunkName: "templateRegist" */ "../views/sms/TemplateRegist.vue"),
        meta: {
          needAuth: true,
          needLogin: true,
          menu: "sms",
          submenu: "template",
        },
      },
      {
        path: "/eventlist",
        component: () =>
          import(/* webpackChunkName: "eventList" */ "../views/contents/EventList.vue"),
        meta: {
          needAuth: true,
          needLogin: true,
          menu: "contents",
          submenu: "event",
        },
      },
      {
        path: "/eventregist",
        component: () =>
          import(/* webpackChunkName: "eventRegist" */ "../views/contents/EventRegist.vue"),
        meta: {
          needAuth: true,
          needLogin: true,
          menu: "contents",
          submenu: "event",
        },
      },
      {
        path: "/eventview",
        name: "eventview",
        component: () =>
          import(/* webpackChunkName: "eventView" */ "../views/contents/EventView.vue"),
        meta: {
          needAuth: true,
          needLogin: true,
          menu: "contents",
          submenu: "event",
        },
        props: true,
      },
      {
        path: "/eventwinnerview",
        name: "eventwinnerview",
        component: () =>
          import(/* webpackChunkName: "eventView" */ "../views/contents/EventWinnerView.vue"),
        meta: {
          needAuth: true,
          needLogin: true,
          menu: "contents",
          submenu: "event",
        },
        props: true,
      },
      {
        path: "/vodmanage",
        component: () =>
          import(/* webpackChunkName: "vodManage" */ "../views/contents/VodManage.vue"),
        meta: {
          needAuth: true,
          needLogin: true,
          menu: "contents",
          submenu: "vod",
        },
      },
      {
        path: "/overseasschedule",
        name: "OverseasSchedule",
        component: () =>
          import(/* webpackChunkName: "vodManage" */ "../views/contents/OverseasSchedule.vue"),
        meta: {
          needAuth: true,
          needLogin: true,
          menu: "contents",
          submenu: "overseasschedule",
        },
      },
      {
        path: "/expertcalc",
        component: () =>
          import(/* webpackChunkName: "vodManage" */ "../views/payment/CalcList.vue"),
        meta: {
          needAuth: true,
          needLogin: true,
          menu: "payment",
          submenu: "inOrder",
        },
      },
      {
        path: "/faqregist",
        name: "faqregist",
        component: () =>
          import(/* webpackChunkName: "faqRegist" */ "../views/contents/FaqRegist.vue"),
        meta: {
          needAuth: true,
          needLogin: true,
          menu: "contents",
          submenu: "board",
        },
        props: true,
      },
      {
        path: "/dailyregist",
        name: "dailyregist",
        component: () =>
          import(/* webpackChunkName: "dailyRegist" */ "../views/contents/DailyRegist.vue"),
        meta: {
          needAuth: true,
          needLogin: true,
          menu: "contents",
          submenu: "board",
        },
        props: true,
      },
      {
        path: "/reviewregist",
        name: "reviewregist",
        component: () =>
          import(/* webpackChunkName: "reviewRegist" */ "../views/contents/ReviewRegist.vue"),
        meta: {
          needAuth: true,
          needLogin: true,
          menu: "contents",
          submenu: "board",
        },
        props: true,
      },
      {
        path: "/ipmanage",
        name: "ipmanage",
        component: () =>
          import(/* webpackChunkName: "ipManage" */ "../views/contents/IpManage.vue"),
        meta: {
          needAuth: true,
          needLogin: true,
          menu: "admin",
          submenu: "manage",
        },
      },
      {
        path: "/vip",
        name: "vipvideomanage",
        component: () =>
          import(/* webpackChunkName: "ipManage" */ "../views/contents/VipVideoManage.vue"),
        meta: {
          needAuth: true,
          needLogin: true,
          menu: "contents",
          submenu: "vip",
        },
      },
    ],
  },
  {
    path: "/signin",
    name: "SignIn",
    meta: {
      needAuth: false,
      needLogin: false,
    },
    component: SignIn,
  },
  {
    path: "/start",
    component: () => import(/* webpackChunkName: "authCheck" */ "../views/AuthCheck.vue"),
    meta: {
      needAuth: false,
      needLogin: false,
    },
  },
  {
    path: "/liveState",
    component: () => import(/* webpackChunkName: "authCheck" */ "../views/LiveState.vue"),
    meta: {
      needAuth: false,
      needLogin: false,
    },
  },
  {
    path: "/vodAvailable",
    component: () => import(/* webpackChunkName: "authCheck" */ "../views/VodAvailable.vue"),
    meta: {
      needAuth: false,
      needLogin: false,
    },
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {},
});

router.beforeEach(async (to, from, next) => {
  console.log(to);
  try {
    if (!to.matched.length) {
      await store.dispatch("dialog/alert", ["error", "잘못된 접근입니다."]);
      await router.push("/");
      next(false);
    }

    if (to.meta.needAuth) {
      const hasPermission = await store.dispatch("auth/checkMenuPermission", {
        menu: to.meta.menu,
        submenu: to.meta.submenu,
      });
      if (hasPermission) {
        next();
      } else {
        await store.dispatch("dialog/alert", ["error", "접근 권한이 없습니다."], false);
        next(false);
      }
    } else {
      next();
    }
  } catch (error) {
    console.error("Error in beforeEach guard:", error);
    next(error);
  }
});

router.afterEach(async (to) => {
  try {
    store.commit("router/route", to);
    await router.checkAuth();
  } catch (error) {
    console.error("Error in afterEach guard:", error);
  }
});

router.checkAuth = async () => {
  try {
    const to = router.currentRoute;
    const connected = store.getters["auth/connected"];
    const initialized = store.getters["auth/initialized"];
    const verified = store.getters["auth/verified"];
    // const userInfo = store.getters["auth/userInfo"];
    const companyInfo = store.getters["auth/companyInfo"];
    // const permission = userInfo ? userInfo.permission : null;

    if (connected && initialized) {
      if (!verified && to.meta.needLogin) {
        await router.push("/signin");
      } else if (verified && to.path === "/signin" && to.path !== "/") {
        await router.push("/");
      } else if (verified && to.meta.needAuth) {
        // const authBool =
        //   to.meta.func === undefined
        //     ? permission[to.meta.category].menu[to.meta.menu].bool
        //     : permission[to.meta.category].menu[to.meta.menu].func[to.meta.func].bool;
        // if (authBool === false) {
        //   await store.dispatch("dialog/alert", ["error", "접근 권한이 없습니다."], false);
        //   router.back();
        // }
      }
    } else if (companyInfo.code !== "") {
      if (to.path !== "/signin" && to.meta.needLogin) {
        await router.push("/signin");
      }
    }
  } catch (error) {
    console.error("Error in checkAuth function:", error);
  }
};

router.goBack = () => {
  router.back();
};
// 전역 참조 허용 함수
(() => {
  window.$router = {
    goBack: router.goBack,
  };
})();

export default router;
