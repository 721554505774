<template>
  <div class="container-wrap d-flex justify-start align-start">
    <div>
      <!-- <p>{{ companyInfo.name }} 관리자</p> -->
      <v-navigation-drawer
        v-model="drawer"
        permanent
        clipped
        color="white"
        app
        class="navigation-wrap pb-14"
      >
        <div class="title-section d-flex flex-column justify-start align-center">
          <v-btn icon color="#FFFFFF">
            <!-- <v-icon>mdi-menu</v-icon> -->
            <!-- <v-icon v-if="!drawer">mdi-backburger</v-icon> -->
            <!-- <p>FutureOn</p> -->
            <v-img
              style="width: 156px; height: 32px"
              cover
              src="@/assets/img/futureon-logo-white.png"
            >
            </v-img>
          </v-btn>
        </div>
        <v-list dense nav>
          <v-list-group
            v-for="menu in menus"
            :key="menu.name"
            link
            color="#FFFFFF"
            :to="`/${menu.path}`"
            active-class="menu-list-active"
            class="title-group"
            @click="menu.expand = !menu.expand"
            :append-icon="null"
          >
            <template v-slot:activator>
              <v-list-item-icon class="ml-1 mr-4" style="padding-top: 1px; margin: auto 0">
                <v-icon color="#9FA8DA">{{ menu.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="menu.name" class="title-menu"></v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon color="#fff">{{
                  menu.expand ? "mdi-chevron-down" : "mdi-chevron-right"
                }}</v-icon>
              </v-list-item-icon>
            </template>
            <v-list-item
              v-for="subItem in menu.submenu"
              :key="subItem.name"
              :to="subItem.path"
              :prepend-icon="subItem.icon"
            >
              <v-list-item-icon class="mr-4">
                <v-icon color="#FFFFFF">mdi-circle-small</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="subItem.name" class="sub-menu"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-navigation-drawer>
    </div>

    <!-- <v-content fluid> -->
    <div class="v-content">
      <div class="head">
        <v-row no-gutters class="d-flex justify-center align-center">
          <v-col class="d-flex justify-end align-center align-self-stretch">
            <v-btn
              class="d-flex justify-center align-center mr-2"
              style="
                width: 44px;
                height: 44px;
                gap: 16px;
                margin-left: 16px;
                margin-right: 16px;
                border-radius: 6px;
                background: #fff;
                border-width: 1px;
                border-color: #e0e0e0;
              "
            >
              <svg
                width="14"
                height="16"
                viewBox="0 0 14 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style="flex-grow: 0; flex-shrink: 0; width: 14px; height: 16px; position: relative"
                preserveAspectRatio="xMidYMid meet"
              >
                <g clip-path="url(#clip0_3078_8817)">
                  <path
                    d="M7.00009 0C6.44697 0 6.00009 0.446875 6.00009 1V1.6C3.71884 2.0625 2.00009 4.08125 2.00009 6.5V7.29375C2.00009 8.7125 1.51572 10.0906 0.631345 11.1969L0.16572 11.7812C-0.0155303 12.0063 -0.0499053 12.3156 0.0750947 12.575C0.200095 12.8344 0.462595 13 0.750095 13H13.2501C13.5376 13 13.8001 12.8344 13.9251 12.575C14.0501 12.3156 14.0157 12.0063 13.8345 11.7812L13.3688 11.2C12.4845 10.0906 12.0001 8.7125 12.0001 7.29375V6.5C12.0001 4.08125 10.2813 2.0625 8.00009 1.6V1C8.00009 0.446875 7.55322 0 7.00009 0ZM7.00009 3C8.93447 3 10.5001 4.56563 10.5001 6.5V7.29375C10.5001 8.79062 10.9345 10.25 11.7407 11.5H2.25947C3.06572 10.25 3.50009 8.79062 3.50009 7.29375V6.5C3.50009 4.56563 5.06572 3 7.00009 3ZM9.00009 14H7.00009H5.00009C5.00009 14.5312 5.20947 15.0406 5.58447 15.4156C5.95947 15.7906 6.46884 16 7.00009 16C7.53134 16 8.04072 15.7906 8.41572 15.4156C8.79072 15.0406 9.00009 14.5312 9.00009 14Z"
                    fill="#26294C"
                  ></path>
                </g>
                <defs>
                  <clipPath id="clip0_3078_8817">
                    <rect width="14" height="16" fill="white"></rect>
                  </clipPath>
                </defs>
              </svg>
            </v-btn>
            <v-btn
              class="d-flex justify-center align-center mr-2"
              style="
                width: 44px;
                height: 44px;
                gap: 16px;
                padding-left: 16px;
                padding-right: 16px;
                border-radius: 6px;
                background: #26294c;
                border-width: 1px;
                border-color: #e0e0e0;
              "
              @click="logOutFunc"
            >
              <svg
                width="16"
                height="14"
                viewBox="0 0 16 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style="flex-grow: 0; flex-shrink: 0; width: 16px; height: 14px; position: relative"
                preserveAspectRatio="none"
              >
                <g clip-path="url(#clip0_3078_8806)">
                  <path
                    d="M11.8094 2.30938L15.6469 6.14687C15.8719 6.37187 16 6.68125 16 7C16 7.31875 15.8719 7.62812 15.6469 7.85312L11.8094 11.6906C11.6094 11.8906 11.3406 12 11.0594 12C10.475 12 10 11.525 10 10.9406V9H6C5.44688 9 5 8.55313 5 8V6C5 5.44688 5.44688 5 6 5H10V3.05937C10 2.475 10.475 2 11.0594 2C11.3406 2 11.6094 2.1125 11.8094 2.30938ZM5 2H3C2.44688 2 2 2.44688 2 3V11C2 11.5531 2.44688 12 3 12H5C5.55312 12 6 12.4469 6 13C6 13.5531 5.55312 14 5 14H3C1.34375 14 0 12.6562 0 11V3C0 1.34375 1.34375 0 3 0H5C5.55312 0 6 0.446875 6 1C6 1.55313 5.55312 2 5 2Z"
                    fill="white"
                  ></path>
                </g>
                <defs>
                  <clipPath id="clip0_3078_8806">
                    <rect width="16" height="14" fill="white"></rect>
                  </clipPath>
                </defs>
              </svg>
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <router-view />
    </div>
    <!-- </v-content> -->

    <div>
      <div class="header-wrap d-flex flex-row-reverse" app>
        <div class="tools-section">
          <!-- 업로드영역 -->
          <!-- <v-tooltip bottom color="black"
            v-if="uploader.filter((r) => r.status === 'progress').length > 0
              || uploader.filter((r) => r.status === 'done').length > 0"
          >
            <template #activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                @click="uploaderShow(true)"
                link
                depressed
                plain
                rounded
                min-width="26"
                width="40"
                height="40"
                class="mr-5"
                active-class="tools-btn-active"
              >
                <v-icon
                  color="black"
                  class="notification"
                >
                  mdi-cloud-upload-outline
                </v-icon>
                <v-badge
                  :color="uploader.filter((r) => r.status === 'progress').length > 0
                    ?'info':'success'"
                  :content="uploader.filter((r) => r.status === 'progress').length > 0
                    ?String(uploader.filter((r) => r.status === 'progress').length)
                    :String(uploader.filter((r) => r.status === 'done').length)"
                  overlap
                  class="mb-6"
                  bordered
                />
              </v-btn>
            </template>
            <span>업로드</span>
          </v-tooltip> -->
          <!-- 업로드 영역 끝 -->
          <!-- 알림 영역 -->
          <!-- <v-tooltip bottom color="black">
            <template #activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                to="/notification"
                link
                depressed
                plain
                rounded
                min-width="26"
                width="40"
                height="40"
                class="mr-5"
                active-class="tools-btn-active"
              >
                <v-icon
                  color="black"
                  class="notification"
                >
                  mdi-bell-outline
                </v-icon>
                <v-badge
                  v-if="unReadCnt > 0"
                  color="error"
                  :content="
                    unReadCnt > 100
                    ?'100+'
                    :unReadCnt
                  "
                  overlap
                  class="mb-6"
                  bordered
                />
              </v-btn>
            </template>
            <span>알림센터</span>
          </v-tooltip> -->
          <!-- 알림영역 끝 -->
          <!-- mypage -->
          <!-- <v-tooltip bottom color="black">
            <template #activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                to="/mypage"
                link
                depressed
                plain
                rounded
                min-width="40"
                width="40"
                height="40"
                class="mr-4"
                active-class="tools-btn-active"
              >
                <v-icon
                  color="black"
                  class="mypage"
                >
                  mdi-account-outline
                </v-icon>
              </v-btn>
            </template>
            <span>내 정보</span>
          </v-tooltip> -->
          <!-- mypage 끝-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "NavSection",
  data: () => ({
    drawer: false,
    mini: true,
    menus: [
      {
        id: "member",
        name: "회원관리",
        bool: true,
        icon: "mdi-account-box-outline",
        expand: false,
        path: "/",
        submenu: {
          totalList: {
            name: "전체회원",
            bool: true,
            icon: "mdi-account-box-multiple-outline",
            path: "/users",
          },
          expiredList: {
            name: "만료예정자목록",
            bool: true,
            icon: "mdi-account-clock-outline",
            path: "/expired",
          },
          vipList: {
            name: "VIP신청",
            bool: true,
            icon: "mdi-account-box-edit-outline",
            path: "/viptrial",
          },
        },
      },
      {
        id: "expert",
        name: "전문가관리",
        bool: true,
        icon: "mdi-finance",
        path: "/expertlist",
        expand: false,
        submenu: {
          lineUp: {
            name: "전문가 라인업",
            bool: true,
            icon: "mdi-card-account-details-outline",
            path: "/expertlist",
          },
          products: {
            name: "전문가 상품",
            bool: true,
            icon: "mdi-archive-edit",
            path: "/products",
          },
          inOrder: {
            name: "전문가 노출순서",
            bool: true,
            icon: "mdi-order-alphabetical-ascending",
            path: "/expertorder",
          },
        },
      },
      {
        id: "contents",
        name: "컨텐츠관리",
        bool: true,
        icon: "mdi-table-of-contents",
        path: "/board",
        expand: false,
        submenu: {
          board: {
            name: "게시판관리",
            bool: true,
            icon: "mdi-bulletin-board",
            path: "/board",
          },
          calendar: {
            name: "주간갤린더",
            bool: true,
            icon: "mdi-calendar-clock",
            path: "/calendarList",
          },
          banner: {
            name: "배너관리",
            bool: true,
            icon: "mdi-sign-real-estate",
            path: "/banner",
          },
          vod: {
            name: "영상관리",
            bool: true,
            icon: "mdi-video-box",
            path: "/vodmanage",
          },
          event: {
            name: "이벤트관리",
            bool: true,
            icon: "mdi-calendar-star",
            path: "/eventlist",
          },
          terms: {
            name: "약관관리",
            bool: true,
            icon: "mdi-file-document-edit-outline",
            path: "/terms",
          },
          vip: {
            name: "VIP 체험 영상 관리",
            bool: true,
            icon: "mdi-video-box",
            path: "/vip",
          },
          overseasschedule: {
            name: "오늘의 해외일정 관리",
            bool: true,
            icon: "mdi-bulletin-board",
            path: "/overseasschedule",
          },
        },
      },
      {
        id: "academy",
        name: "교육아카데미",
        bool: true,
        icon: "mdi-school-outline",
        path: "/lectureseries",
        expand: false,
        submenu: {
          board: {
            name: "강의시리즈",
            bool: true,
            icon: "mdi-human-male-board",
            path: "/lectureseries",
          },
          // calendar: {
          //   name: "강의관리",
          //   bool: true,
          //   icon: "mdi-subtitles-outline",
          //   path: "/lecture-list",
          // },
          inOrder: {
            name: "강의 노출순서",
            bool: true,
            icon: "mdi-order-numberic-ascending",
            path: "/lecture-order",
          },
        },
      },
      {
        id: "sms",
        name: "문자관리",
        bool: true,
        icon: "mdi-message-check-outline",
        path: "/smslist",
        expand: false,
        submenu: {
          sms: {
            name: "문자발송관리",
            bool: true,
            icon: "mdi-cellphone-message",
            path: "/smslist",
          },
          template: {
            name: "문자템플릿관리",
            bool: true,
            icon: "mdi-application-array-outline",
            path: "/smstemplate",
          },
        },
      },
      {
        id: "payment",
        name: "결제관리",
        bool: true,
        icon: "mdi-credit-card-multiple-outline",
        path: "/payments",
        expand: false,
        submenu: {
          board: {
            name: "결제내역",
            bool: true,
            icon: "mdi-invoice-list",
            path: "/payments",
          },
          calendar: {
            name: "환불내역",
            bool: true,
            icon: "mdi-list-status",
            path: "/refund",
          },
          inOrder: {
            name: "전문가정산",
            bool: true,
            icon: "mdi-list-box-outline",
            path: "/expertcalc",
          },
        },
      },
      {
        id: "admin",
        name: "관리자설정",
        bool: true,
        icon: "mdi-account-supervisor-circle-outline",
        path: "/manage",
        expand: false,
        submenu: {
          manage: {
            name: "관리설정",
            bool: true,
            icon: "mdi-account-supervisor-outline",
            path: "/manage",
          },
          ip: {
            name: "접속제한관리",
            bool: true,
            icon: "mdi-lock-outline",
            path: "/ipmanage",
          },
        },
      },
      {
        id: "manual",
        name: "사용자 매뉴얼",
        bool: true,
        icon: "mdi-book-open-page-variant",
        path: "/manual",
        expand: false,
        submenu: {
          manage: {
            name: "가이드 보기",
            bool: true,
            icon: "mdi-book-open-page-variant",
            path: "/manual",
          },
        },
      },
    ],
  }),
  computed: {
    ...mapGetters({
      companyInfo: "auth/companyInfo",
      userInfo: "auth/userInfo",
      verified: "auth/verified",
    }),
  },
  methods: {
    ...mapMutations({
      verifiedCng: "auth/verified",
    }),
    ...mapActions({
      logout: "auth/logout",
    }),
    logOutFunc() {
      this.logout();
    },
  },
  watch: {},
};
</script>

<style scoped lang="scss">
.container-wrap {
  width: 100%;
}
.v-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-grow: 0;
  flex-shrink: 0;
  // height: 1080px;
  background: #f8f9fa;
  // width: 1620px;
  margin: 0 auto;
  .head {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 70px;
    width: 1620px;
    gap: 10px;
    padding-left: 30px;
    padding-right: 30px;
    background: #fff;
    border-top-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 1px;
    border-left-width: 0px;
    border-color: #e0e0e0;
    .head-wrap-row {
      align-self: stretch;
      gap: 827px;
      .head-wrap-col {
        width: 44px;
        height: 44px;
        position: relative;
        gap: 16px;
        padding-left: 16px;
        padding-right: 16px;
        border-radius: 6px;
        background: #fff;
        border-width: 1px;
        border-color: #e0e0e0;
      }
    }
  }
}
.navigation-wrap {
  width: 280px !important;
  background-color: #26294d !important;
  color: #fff !important;
}

.title-section {
  padding: 20px 40px;
  // position:fixed;
  background-color: #26294d;
  // width: 300px;
  .v-btn {
    // width: 56px;
    width: 156px;
    height: 56px;
    background-color: #26294d;
    border-radius: 0px;
    .v-icon {
      font-size: 2rem;
    }
  }
}
.title-menu {
  font-size: 17px !important;
  line-height: 1.2em !important;
  color: #fff !important;
}
.title-group {
  min-height: 58px;
}
.sub-menu {
  font-size: 14px !important;
  line-height: 1em !important;
  color: #fff !important;
}
.menu-list-active {
  background-color: #4589ff !important;
  .v-list-item__title {
    color: #000;
  }
}
</style>
