<template>
  <div class="outerWrap">
    <div class="innerWrap">
      <div class="box">
        <div class="title">
          <div class="company">
            <img src="../assets/img/companyIcon.svg" alt="admin" />
            <p>{{ companyInfo.name }}</p>
          </div>
        </div>
        <div class="brand">
          <img class="brand" src="../assets/img/manoadmin.svg" alt="mano-admin" />
        </div>
        <div class="formWrap">
          <v-form class="form" @submit.prevent="loginMethod">
            <v-checkbox
              class="check"
              :label="`아이디 저장`"
              color="primary"
              v-model="user.saved"
            ></v-checkbox>
            <v-text-field
              autofocus
              label="아이디"
              outlined
              color="primary"
              hide-details
              class="mb-5"
              v-model="user.id"
            ></v-text-field>
            <v-text-field
              label="비밀번호"
              outlined
              color="primary"
              type="password"
              hide-details
              v-model="user.pw"
            ></v-text-field>
            <v-btn
              type="submit"
              block
              depressed
              color="primary"
              x-large
              class="mt-10 mb-2"
              :disabled="loginDisabled"
            >
              로그인
            </v-btn>
          </v-form>
        </div>
      </div>
    </div>
    <p class="bottomMsg">Copyright © DemandCurve. All rights reserved.</p>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
// import axios from 'axios';

export default {
  name: "SignIn",
  components: {},
  computed: {
    ...mapGetters({
      userInfo: "auth/userInfo",
      companyInfo: "auth/companyInfo",
      router: "router/path",
    }),
  },
  data: () => ({
    user: {
      id: "",
      pw: "",
      saved: false,
    },
    loginDisabled: true,
  }),
  methods: {
    ...mapMutations({
      progress: "dialog/progress",
    }),
    ...mapActions({
      alert: "dialog/alert",
      authorization: "auth/authorization",
    }),
    async loginMethod() {
      if (this.loginDisabled === false) {
        this.progress(true);
        const url = "/init/admLogin";
        const data = {
          user_id: this.user.id,
          password: this.user.pw,
        };
        try {
          const response = await this.$axios.post(url, data);
          if (response.data.result === "success") {
            const saveID = JSON.stringify({
              id: this.user.saved ? this.user.id : "",
              saved: this.user.saved,
            });
            localStorage.setItem(`DCurve.${this.companyInfo.code}.saveID`, saveID);
            await this.authorization();
            this.progress(false);
          } else {
            if (response.name === "ACCESS_DENIED") {
              this.alert(["error", "비밀번호를 확인해주세요."]);
            } else if (response.name === "NO_USER") {
              this.alert(["error", "등록되지 않은 계정입니다."]);
            } else if (response.name === "RETIRED_USER") {
              this.alert(["error", "퇴사처리된 계정입니다. 복구를 원할 시 관리자에게 문의하세요."]);
            } else if (response.name === "DELETED") {
              this.alert(["error", "삭제된 계정입니다."]);
            } else {
              this.alert(["error", "로그인에 실패하였습니다."]);
            }
            this.progress(false);
          }
        } catch (error) {
          if (error.response.data.message === "ALREADY_LOGIN") {
            this.alert(["warning", "이미 로그인 되어 있는 계정입니다. 관리자에게 문의하세요."]);
          } else {
            this.alert(["error", "오류가 발생했습니다. 다시 시도해주세요."]);
          }
          this.progress(false);
        }
      }
    },
    idSavedCheckFunc() {
      // 아이디 저장 체크
      const saveID = JSON.parse(localStorage.getItem(`DCurve.${this.companyInfo.code}.saveID`));
      if (saveID && saveID.id && saveID.saved) {
        this.user.id = saveID.id;
        this.user.saved = saveID.saved;
      }
    },
  },
  watch: {
    user: {
      deep: true,
      handler() {
        // 아이디, 비번 입력시 로그인버튼 활성화
        const id = this.user.id.trim();
        const pw = this.user.pw.trim();
        if (id === "" || pw === "") {
          this.loginDisabled = true;
        } else {
          this.loginDisabled = false;
        }
      },
    },
    companyInfo: {
      handler() {
        this.idSavedCheckFunc();
      },
    },
  },
  mounted() {
    this.idSavedCheckFunc();
  },
};
</script>

<style lang="scss" scoped>
.outerWrap {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: #fafafa;
  flex-wrap: wrap;
  .bottomMsg {
    width: 100%;
    text-align: center;
    margin-top: 8px;
    color: #555;
    font-weight: 300;
    font-size: 0.8rem;
  }
  .innerWrap {
    width: 400px;
    .box {
      width: 100%;
      border-radius: 30px;
      background-color: #fff;
      border: 1px solid #ddd;
      padding: 30px;
      padding-top: 25px;
      .title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .company {
          display: flex;
          flex-shrink: 0;
          align-items: center;
          img {
            width: 20px;
            margin-top: 2px;
            margin-right: 4px;
          }
          p {
            font-weight: 700;
            font-size: 1rem;
            margin: 0;
          }
        }
      }
      .brand {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 20px;
        img {
          width: 240px;
        }
      }
      .formWrap {
        display: flex;
        justify-content: center;
        .form {
          width: 100%;
        }
      }
    }
  }
}
</style>
