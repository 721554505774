import Vue from 'vue';
import Vuex from 'vuex';

import auth from './auth';
// import uploader from './uploader';
import dialog from './dialog';
import router from './router';
// import notification from './notification';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    // uploader,
    dialog,
    router,
    // notification,
  },
  plugins: [
    createPersistedState({
      paths: ["auth"]
    })
  ],
});
