<template>
  <v-app>
    <progress-circle />
    <dlg-alert />
    <dlg-confirm
      :show.sync="confirm.show"
      :func="confirm.func"
      :msg="confirm.msg"
      :btnText="confirm.btnText"
      :color="confirm.color"
    />
    <!-- <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
          transition="scale-transition"
          width="40"
        />

        <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
          width="100"
        />
      </div>

      <v-spacer></v-spacer>

      <v-btn
        href="https://github.com/vuetifyjs/vuetify/releases/latest"
        target="_blank"
        text
      >
        <span class="mr-2">Latest Release</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar> -->

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import ProgressCircle from "@/components/ProgressCircle.vue";
import DlgAlert from "@/components/dialog/DlgAlert.vue";
import DlgConfirm from "@/components/dialog/DlgConfirm.vue";

export default {
  name: "App",

  components: {
    DlgAlert,
    ProgressCircle,
    DlgConfirm,
  },
  data: () => ({
    //
  }),
  computed: {
    ...mapGetters({
      connected: "auth/connected",
      initialized: "auth/initialized",
      userInfo: "auth/userInfo",
      verified: "auth/verified",
      confirm: "dialog/confirm",
      // post: 'dialog/post',
      // sale: 'dialog/sale',
      // memo: 'dialog/memo',
      route: "router/route",
      path: "router/path",
      // coinmemo: 'dialog/coinmemo',
    }),
  },
  watch: {
    initialized: {
      immediate: true,
      handler() {
        this.$router.checkAuth();
      },
    },
    connected: {
      immediate: true,
      handler() {
        this.$router.checkAuth();
      },
    },
    verified: {
      immediate: true,
      handler() {
        this.$router.checkAuth();
      },
    },
    userInfo: {
      deep: true,
      handler(val) {
        if (this.verified === true) {
          this.permissionToMenus(val.permission);
        }
      },
    },
    path: {
      handler(val) {
        if (val !== null) {
          this.$router.push(val);
          this.push(null);
        }
      },
    },
    route: {
      handler() {
        if (this.verified === true) {
          // this.authorization(0);
        }
      },
    },
  },
  mounted() {
    this.authCheck();
  },
  methods: {
    ...mapMutations({
      progress: "dialog/progress",
      push: "router/push",
    }),
    ...mapActions({
      authorization: "auth/authorization",
      permissionToMenus: "auth/permissionToMenus",
    }),
    async authCheck() {
      await this.authorization();
    },
  },
};
</script>
<style>
@font-face {
  font-family: "Pretendard Variable";
  font-weight: 45 920;
  font-style: normal;
  font-display: swap;
  src: local("Pretendard Variable"),
    url("@/assets/fonts/PretendardVariable.woff2") format("woff2-variations");
}
* {
  font-family: "Pretendard", "Montserrat", Malgun Gothic, "Malgun Gothic", sans-serif;
  word-break: keep-all;
}
</style>
